<h2>Copy Workout</h2>
<mat-stepper orientation="vertical" [linear]="true" #stepper>
    <mat-step>
        <ng-template matStepLabel>Source</ng-template>
        <p>Where would you like to copy the workout from?</p>
        <mat-form-field class="w-100">
            <mat-label>Workout source</mat-label>
            <mat-select [(ngModel)]="source" required>
                <mat-option [value]="'calendar'">Calendar</mat-option>
                <mat-option [value]="'weekly-plan'" disabled>Weekly Plan</mat-option>
                <mat-option [value]="'my-workouts'" disabled>My Workouts</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" matStepperNext class="float-right"
                [disabled]="!source">NEXT</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Options</ng-template>
        @if (source == 'calendar') {
        <p>Which day?</p>
        <mat-calendar [(selected)]="date"></mat-calendar>
        <div>
            <button mat-raised-button color="primary" matStepperNext class="ml-3 float-right"
                [disabled]="!date">NEXT</button>
            <button mat-raised-button matStepperPrevious class="float-right">BACK</button>
        </div>
        }
        @else if (source == 'weekly-plan') {

        }
        @else {

        }
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Adjustments</ng-template>
        <p>Would you like to adjust the workout intensity?</p>
        <mat-form-field class="w-100">
            <mat-label>Adjustment</mat-label>
            <mat-select [(ngModel)]="adjustments" required>
                <mat-option [value]="'none'">No, copy workout as is.</mat-option>
                <mat-option [value]="'more-reps'">Yes, adjust for more reps.</mat-option>
                <mat-option [value]="'more-weight'">Yes, adjust for more weight.</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" matStepperNext class="ml-3 float-right"
                [disabled]="!adjustments">NEXT</button>
            <button mat-raised-button matStepperPrevious class="float-right">BACK</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Confirm your selections</ng-template>
        <mat-list>
            <mat-divider></mat-divider>

            @if (source == 'calendar') {
            <mat-list-item>
                <p>
                    <mat-icon class="mr-3" fontIcon="check"></mat-icon>
                    <span>Copy from {{ date | date:'LLLL dd' }}.</span>
                </p>
            </mat-list-item>
            }
            @else if (source == 'weekly-plan') {

            }
            @else {

            }

            <mat-divider></mat-divider>

            @if (adjustments == 'none') {
            <mat-list-item>
                <p>
                    <mat-icon class="mr-3" fontIcon="check"></mat-icon>
                    <span>No adjustments.</span>
                </p>
            </mat-list-item>
            }
            @else if (adjustments == 'more-reps') {
            <mat-list-item>
                <p>
                    <mat-icon class="mr-3" fontIcon="check"></mat-icon>
                    <span>Adjust for more reps.</span>
                </p>
            </mat-list-item>
            }
            @else {
            <mat-list-item>
                <p>
                    <mat-icon class="mr-3" fontIcon="check"></mat-icon>
                    <span>Adjust for more weight.</span>
                </p>
            </mat-list-item>
            }
        </mat-list>
        <mat-divider></mat-divider>
        
        <div class="mt-3">
            <button mat-raised-button color="primary" class="ml-3 float-right" (click)="confirm()">CONFIRM</button>
            <button mat-raised-button matStepperPrevious class="float-right">BACK</button>
        </div>
    </mat-step>
</mat-stepper>