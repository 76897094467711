import { Injectable } from '@angular/core';
import { ApiService } from '../abstract/api.service';
import { Observable } from 'rxjs';
import { MovementPattern } from '../../models/domain/movement-pattern.model';
import { ExerciseMetrics } from '../../models/analytics/exercise-metrics.model';
import { Exercise } from '../../models/domain/exercise.model';
import { ExerciseSet } from '../../models/domain/exercise-set.model';
import { GetExerciseMetricsRequest } from '../../models/requests/get-exercise-metrics-request.model';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService extends ApiService {
  private readonly loadAdjustment: number = 5;
  private readonly repsAdjustment: number = 2;
  private readonly cardioTimeAdjustment: number = 5;
  private readonly endpoint: string = "Exercise/"

  public getMovementPatterns(): Observable<MovementPattern[]> {
    return this.get(this.endpoint + "GetMovementPatterns");
  }

  public getMetrics(parameters: GetExerciseMetricsRequest): Observable<ExerciseMetrics[]> {
    return this.post(this.endpoint + "GetExerciseMetrics", parameters);
  }

  public autoAdjustExercise(exercise: Exercise, adjustments: 'none' | 'more-reps' | 'more-weight'): Exercise {
    if (adjustments == 'none') {
      return exercise;
    }

    let averageReps = this.getAverageReps(exercise.sets);
    let baseLoad = this.getBaseLoad(exercise.sets);

    exercise.sets.forEach((set: ExerciseSet) => {
      if (exercise.muscleGroup == 7 || exercise.muscleGroup?.toString() == 'Cardio') {
        if (adjustments == 'more-reps') {
          set.repetitions! += this.cardioTimeAdjustment;
        }
        else if (adjustments == 'more-weight') {
          if (set.load == 1) {
            set.load = 3;
          }
          else if (set.load == 3) {
            set.load = 5;
          }
        }
      }
      else {
        if (adjustments == 'more-reps') {
          set.repetitions = averageReps + this.repsAdjustment;

          if (baseLoad - this.loadAdjustment > 0) {
            set.load = baseLoad - this.loadAdjustment;
          }
        }
        else if (adjustments == 'more-weight') {
          set.load = baseLoad + this.loadAdjustment;

          if (averageReps - this.repsAdjustment > 0) {
            set.repetitions = averageReps - this.repsAdjustment;
          }
        }
      }
    });

    return exercise;
  }

  private getBaseLoad(sets: ExerciseSet[]): number {
    if (sets.length == 0) {
      return 0;
    }
    else if (sets.length == 1) {
      return sets[0].load ?? 0;
    }

    let ordered = sets.map(s => s.load).sort();

    return ordered[ordered.length - 2] ?? 0;
  }

  private getAverageReps(sets: ExerciseSet[]): number {
    if (sets.length == 0) {
      return 0;
    }
    else if (sets.length == 1) {
      return sets[0].repetitions ?? 0;
    }

    let sum = 0;

    sets.forEach(set => {
      sum += set.repetitions!;
    });

    return Math.trunc(sum / sets.length);
  }
}
