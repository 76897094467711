import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  private jwt: JwtHelperService = new JwtHelperService();

  public getDecoded(token: string): any {
    return this.jwt.decodeToken(token);
  }

  public isExpired(token: string): boolean {
    if (!token)
      return false;

    let decoded = this.getDecoded(token);

    if (!decoded || !decoded.exp)
      return false;

    return Number(decoded.exp) <= Number(Date.now())/1000;
  }

  public getRole(token: string): string {
    let decoded = this.getDecoded(token);

    return decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  }

  public getUser(token: string): string {
    let decoded = this.getDecoded(token);

    return decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  }
}
