import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Credentials } from '../../../models/authentication/credentials.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';
import { DialogService } from '../../../services/utilities/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatCardModule,
    MatProgressBarModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  public loginStatus: ProgressBarMode = "determinate";
  public credentials: Credentials = new Credentials();

  constructor(private authService: AuthenticationService,
              private dialogService: DialogService,
              private router: Router) {}

  ngOnInit(): void {
    let isLoggedIn: boolean = this.authService.isAuthenticated();

    if (isLoggedIn) {
      this.router.navigate(["dashboard"]);
    }
  }

  public signIn(): void {
    this.loginStatus = "query";
    let success = () => {
      this.loginStatus = 'determinate';

      this.router.navigate(["dashboard"]);
    };
    let error = (err: any) => {
      this.dialogService.showSnackbar('Invalid credentials. Please try again.', 'DISMISS', 5000);
      this.loginStatus = 'determinate';
    };

    this.authService.authenticate(this.credentials, success, error, () => {});
  }
}