import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExerciseComponent } from '../exercise/exercise.component';
import { WorkoutStatsComponent } from '../workout-stats/workout-stats.component';
import { Exercise } from '../../../models/domain/exercise.model';
import { v4 as uuidv4 } from 'uuid';
import { ExerciseSet } from '../../../models/domain/exercise-set.model';
import { DialogService } from '../../../services/utilities/dialog.service';
import { Workout } from '../../../models/domain/workout.model';
import { WorkoutService } from '../../../services/workout/workout.service';
import { MovementGroup } from '../../../models/presentation/movement-group.model';
import { WorkoutDate } from '../../../models/presentation/workout-date.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '../../../environments/environment';
import {
  MatDialog,
} from '@angular/material/dialog';
import { FloatingMenuComponent } from '../../common/floating-menu/floating-menu.component';
import { TimeService } from '../../../services/utilities/time.service';
import { CopyWorkoutDialog } from './dialogs/copy-workout/copy-workout-dialog.component';
import { MuscleGroupDialog } from './dialogs/muscle-group/muscle-group-dialog.component';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CopyWorkoutBottomSheet, CopyWorkoutSelections } from './dialogs/steppers/copy-workout/copy-workout-bottomsheet.component';
import { ExerciseService } from '../../../services/exercise/exercise.service';

@Component({
  selector: 'app-workout',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ExerciseComponent,
    FloatingMenuComponent,
    MatBottomSheetModule,
    WorkoutStatsComponent
  ],
  templateUrl: './workout.component.html',
  styleUrl: './workout.component.css'
})
export class WorkoutComponent implements OnInit {
  private _date?: WorkoutDate | undefined;

  @Input()
  public set date(value: WorkoutDate | undefined) {
    this._date = value;

    this.loadWorkout();
  }
  public get date(): WorkoutDate | undefined {
    return this._date;
  }
  @Input()
  public movementGroups!: MovementGroup[];
  public workout?: Workout;
  public isLoaded: boolean = false;
  public isError: boolean = false;
  public floatingMenuOptions: string[] = ['add', 'save'];
  public floatingMenuOptionIcons: string[] = ['add', 'save'];
  @ViewChild('stats')
  private stats!: WorkoutStatsComponent;

  constructor(private dialogService: DialogService,
    private workoutService: WorkoutService,
    private timeService: TimeService,
    private exerciseService: ExerciseService,
    private muscleDialog: MatDialog,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    let today = this.timeService.todayLocal();
    this.date ??= new WorkoutDate(today);
    this.workout ??= new Workout(this.date.date);

    this.loadWorkout();
  }

  public addExercise(exercise: Exercise | undefined): void {
    if (!exercise) {
      exercise = new Exercise(this.workout?.exercises.length ?? 0);
    }

    this.workout!.exercises.push(exercise);
    this.stats?.calculate();

    this.dialogService.showSnackbar("New exercise added!", "DISMISS", 2000);
  }

  public copyExercise(exercise: Exercise): void {
    let copy = this.cloneExercise(exercise);
    this.workout!.exercises.push(copy);
    this.stats?.calculate();
    
    this.dialogService.showSnackbar("Exercise cloned!", "DISMISS", 2000);
  }

  public removeExercise(exercise: Exercise): void {
    this.workout!.exercises = this.workout!.exercises.filter(e => e.id != exercise.id);
    this.stats?.calculate();
    
    this.dialogService.showSnackbar("Exercise removed!", "DISMISS", 2000);
  }

  public save(): void {
    this.dialogService.showSnackbar("Saving workout...", "DISMISS");

    this.workout!.date = this.date?.date!;
    this.workout?.exercises.forEach(exercise => {
      exercise.sets.forEach(set => {
        if (exercise.muscleGroup == 7 || exercise.muscleGroup?.toString() == 'Cardio') {
          set.unit = 2;
        }

        if (set.load == null || set.load == undefined) {
          set.load = 0;
        }

        if (set.repetitions == null || set.repetitions == undefined) {
          set.repetitions = 0;
        }
      });
    });

    let sub = this.workoutService.saveWorkout(this.workout!).subscribe({
      next: () => {
        this.stats?.calculate();
        
        this.dialogService.showSnackbar("Workout saved!", "DISMISS", 2000);
      },
      error: (err: any) => {
        this.dialogService.showSnackbar("Unable to save workout right now.", "DISMISS", 2000);

        if (!environment.production) {
          console.log(err);
        }
      },
      complete: () => {
        sub.unsubscribe();
      }
    });
  }

  public openMuscleDialog(): void {
    const dialogRef = this.muscleDialog.open(MuscleGroupDialog, {
      data: { muscle: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }

      let exercise = new Exercise(this.workout?.exercises.length ?? 0);
      exercise.muscleGroup = result;

      this.addExercise(exercise);
    });
  }

  public openCopyDialog(): void {
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    const dialogRef = this.muscleDialog.open(CopyWorkoutDialog, {
      data: { date: lastWeek },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }

      this.isLoaded = false;
      let parsed = TimeService.getStringFromDate(result);
      let date = new WorkoutDate(parsed);
      let sub = this.workoutService.getWorkout(date).subscribe({
        next: (response: Workout) => {
          if (!response || !response.exercises || response.exercises.length == 0) {
            this.dialogService.showSnackbar("No workout found for selected date.", "DISMISS", 2000);
            this.isLoaded = true;

            return;
          }

          response.id = uuidv4();
          response.date = this.date!.date;
          response.exercises.forEach(exercise => {
            let copy = this.cloneExercise(exercise);
            this.workout!.exercises.push(copy);
          });

          this.isLoaded = true;
        },
        error: () => {
          this.isError = true;
          this.dialogService.showSnackbar("Unable to load exercises. Try again later.", "DISMISS", 2000);
        },
        complete: () => {
          sub.unsubscribe();
        }
      });
    });
  }

  public onSelect(option: string): void {
    if (option == 'add') {
      this.openMuscleDialog();
    }
    else if (option == 'save') {
      this.save();
    }
  }

  public openCopyBottomSheet(): void {
    const sheetRef = this.bottomSheet.open(CopyWorkoutBottomSheet);

    sheetRef.afterDismissed().subscribe((result: CopyWorkoutSelections) => {
      if (!result) {
        return;
      }

      this.isLoaded = false;

      if (result.source == 'calendar') {
        let date = new WorkoutDate(result.date);
        let sub = this.workoutService.getWorkout(date).subscribe({
          next: (response: Workout) => {
            if (!response || !response.exercises || response.exercises.length == 0) {
              this.dialogService.showSnackbar("No workout found for selected date.", "DISMISS", 2000);
              this.isLoaded = true;

              return;
            }

            response.id = uuidv4();
            response.date = this.date!.date;
            response.exercises.forEach(exercise => {
              let copy = this.cloneExercise(exercise);
              copy = this.exerciseService.autoAdjustExercise(copy, result.adjustments);

              this.workout!.exercises.push(copy);
            });

            this.isLoaded = true;
            this.dialogService.showSnackbar("Workout copied. Hit the Save button to keep it.", "DISMISS", 5000);
          },
          error: () => {
            this.isError = true;
            this.dialogService.showSnackbar("Unable to load exercises. Try again later.", "DISMISS", 2000);
          },
          complete: () => {
            sub.unsubscribe();
          }
        });
      }
      else if (result.source == 'weekly-plan') {
        console.log(result);
      }
      else {
        console.log(result);
      }
    });
  }

  public setsChanged(): void {
    this.stats?.calculate();
  }

  private loadWorkout(): void {
    if (!this.date) {
      return;
    }

    this.isLoaded = false;
    let sub = this.workoutService.getWorkout(this.date!).subscribe({
      next: (response: Workout) => {
        this.workout = response;
        this.isLoaded = true;
      },
      error: () => {
        this.isError = true;
        this.dialogService.showSnackbar("Unable to load exercises. Try again later.", "DISMISS", 2000);
      },
      complete: () => {
        sub.unsubscribe();
      }
    });
  }

  private cloneExercise(exercise: Exercise): Exercise {
    let copy = new Exercise(this.workout?.exercises.length ?? 0);
    copy.id = uuidv4();
    copy.notes = exercise.notes;
    copy.sets = [];
    copy.movementPatternId = exercise.movementPatternId;
    copy.muscleGroup = exercise.muscleGroup;
    copy.variation = exercise.variation;
    copy.equipment = exercise.equipment;
    copy.advancedTechnique = exercise.advancedTechnique;

    exercise.sets.forEach((set: ExerciseSet) => {
      let s = { ...set };
      s.id = uuidv4();
      s.order = copy.sets.length;
      copy.sets.push(s);
    });

    return copy;
  }
}