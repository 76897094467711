import { v4 as uuidv4 } from 'uuid';

export class ExerciseSet {
    public id!: string;
    public order!: number;
    public repetitions?: number;
    public load?: number;
    public unit: 0 | 1 | 2 = 0;

    constructor(order: number) {
        this.id = uuidv4();
        this.order = order;
    }
}