import { Component, Inject, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { MatTabsModule } from '@angular/material/tabs';
import { ExerciseMetrics } from '../../../../../models/analytics/exercise-metrics.model';
import { ExerciseService } from '../../../../../services/exercise/exercise.service';
import { DialogService } from '../../../../../services/utilities/dialog.service';
import { MovementPattern } from '../../../../../models/domain/movement-pattern.model';
import { TimeService } from '../../../../../services/utilities/time.service';
import { environment } from '../../../../../environments/environment';
import { GetExerciseMetricsRequest } from '../../../../../models/requests/get-exercise-metrics-request.model';

@Component({
    selector: 'exercise-stats-dialog',
    templateUrl: 'exercise-stats-dialog.component.html',
    standalone: true,
    imports: [
      CommonModule,
      MatDialogModule,
      MatButtonModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatDividerModule,
      MatTabsModule,
      NgChartsModule
    ],
    providers: [
      { provide: NgChartsConfiguration, useValue: { generateColors: false } }
    ]
  })
  export class ExerciseStatsDialog {
    public isLoaded: boolean = false;
    public metrics!: ExerciseMetrics[];
    public columns: string[] = ['date', 'averageLoad', 'maxLoad', 'unit', 'averageReps', 'maxReps', 'sets', 'volume'];
  
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
    public lineChartType: ChartType = 'line';
    public loadChartData!: ChartConfiguration['data'];
    public repsChartData!: ChartConfiguration['data'];
    public volumeChartData!: ChartConfiguration['data'];
    public loadChartOptions: ChartConfiguration['options'] = {
      responsive: true,
      elements: {
        line: {
          tension: 0.5
        },
      },
      scales: {
        y: {
          position: 'left',
          beginAtZero: false,
          ticks: {
            color: '#808080'
          }
        },
        x: {
          ticks: {
            color: '#808080'
          }
        }
      },
      plugins: {
        legend: { 
          display: true,
          position: 'bottom',
          labels: {
            color: '#fafafa'
          }
        }
      },
    };
    public repsChartOptions: ChartConfiguration['options'] = {
      responsive: true,
      elements: {
        line: {
          tension: 0.5
        },
      },
      scales: {
        y: {
          position: 'left',
          beginAtZero: true,
          ticks: {
            color: '#808080'
          }
        },
        x: {
          ticks: {
            color: '#808080'
          }
        }
      },
      plugins: {
        legend: { 
          display: true,
          position: 'bottom',
          labels: {
            color: '#fafafa'
          }
        }
      },
    };
    public volumeChartOptions: ChartConfiguration['options'] = {
      responsive: true,
      elements: {
        line: {
          tension: 0.5
        },
      },
      scales: {
        y: {
          position: 'left',
          beginAtZero: true,
          ticks: {
            color: '#808080'
          }
        },
        x: {
          ticks: {
            color: '#808080'
          }
        }
      },
      plugins: {
        legend: { 
          display: true,
          position: 'bottom',
          labels: {
            color: '#fafafa'
          }
        }
      },
    };
  
    constructor(public dialogRef: MatDialogRef<ExerciseStatsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: ExerciseStatsDialogData,
      private exerciseService: ExerciseService,
      private dialogService: DialogService) {
      this.loadStats(data.parameters);
    }
  
    private loadStats(parameters: GetExerciseMetricsRequest): void {
      let sub = this.exerciseService.getMetrics(parameters!).subscribe({
        next: (result: ExerciseMetrics[]) => {
          if (!result || result.length < 2) {
            this.dialogService.showSnackbar('Not enough history found for this exercise. You need at least two days worth of data to see exercise metrics.', 'DISMISS', 2000);
            this.dialogRef.close();
          }
  
          this.metrics = result.reverse();
  
          this.loadChartData = {
            datasets: [
              {
                data: this.metrics.map(m => m.averageLoad!),
                label: 'Average',
                backgroundColor: 'rgba(148,159,177,0.2)',
                borderColor: 'rgba(148,159,177,1)',
                pointBackgroundColor: 'rgba(148,159,177,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)',
                fill: 'origin',
              },
              {
                data: this.metrics.map(m => m.maxLoad!),
                label: 'Max',
                backgroundColor: 'rgba(194, 24, 91, 0.5)',
                borderColor: '#c2185b',
                pointBackgroundColor: 'rgba(194, 24, 91, 0.5)',
                pointBorderColor: '#c2185b',
                pointHoverBackgroundColor: '#c2185b',
                pointHoverBorderColor: 'rgba(194, 24, 91, 0.5)',
                fill: 'origin',
              },
            ],
            labels: this.metrics.map(m => this.getDateLabel(TimeService.getStringFromDate(new Date(m.date!))))
          };
  
          this.repsChartData = {
            datasets: [
              {
                data: this.metrics.map(m => m.averageReps!),
                label: 'Average',
                backgroundColor: 'rgba(148,159,177,0.2)',
                borderColor: 'rgba(148,159,177,1)',
                pointBackgroundColor: 'rgba(148,159,177,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)',
                fill: 'origin',
              },
              {
                data: this.metrics.map(m => m.maxReps!),
                label: 'Max',
                backgroundColor: 'rgba(194, 24, 91, 0.5)',
                borderColor: '#c2185b',
                pointBackgroundColor: 'rgba(194, 24, 91, 0.5)',
                pointBorderColor: '#c2185b',
                pointHoverBackgroundColor: '#c2185b',
                pointHoverBorderColor: 'rgba(194, 24, 91, 0.5)',
                fill: 'origin',
              },
            ],
            labels: this.metrics.map(m => this.getDateLabel(TimeService.getStringFromDate(new Date(m.date!))))
          };
  
          this.volumeChartData = {
            datasets: [
              {
                data: this.metrics.map(m => m.volume!),
                label: 'Volume',
                backgroundColor: 'rgba(194, 24, 91, 0.5)',
                borderColor: '#c2185b',
                pointBackgroundColor: 'rgba(194, 24, 91, 0.5)',
                pointBorderColor: '#c2185b',
                pointHoverBackgroundColor: '#c2185b',
                pointHoverBorderColor: 'rgba(194, 24, 91, 0.5)',
                fill: 'origin',
              },
            ],
            labels: this.metrics.map(m => this.getDateLabel(TimeService.getStringFromDate(new Date(m.date!))))
          };
        },
        error: (err: any) => {
          this.dialogService.showSnackbar("Unable to load stats right now.", "DISMISS", 2000);
          this.isLoaded = true;
  
          if (!environment.production) {
            console.log(err);
          }
        },
        complete: () => {
          sub.unsubscribe();
          this.isLoaded = true;
        }
      });
    }
  
    private getDateLabel(value: string): string {
      let date = value.split('T')[0].split('-');
  
      return `${date[1]}-${date[2]}`;
    }
  }
  
  export interface ExerciseStatsDialogData {
    parameters: GetExerciseMetricsRequest;
    movement: MovementPattern;
  }