@if (options) {
    <div id="menu" class="menu-container hide">
        <div class="options-container">
            @for (option of options; track $index) {
            <div class="button-container">
                <button mat-mini-fab (click)="onButtonClick(option)" color="primary">
                    @if (icons && icons[$index]) {
                    <mat-icon class="m-0">{{ icons[$index] }}</mat-icon>
                    }
                    @else {
                    <p>{{ option }}</p>
                    }
                </button>
            </div>
            }
        </div>
    </div>
}