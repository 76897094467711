import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { Exercise } from '../../../models/domain/exercise.model';
import { WorkoutService } from '../../../services/workout/workout.service';

@Component({
  selector: 'app-workout-stats',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatDividerModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  templateUrl: './workout-stats.component.html',
  styleUrl: './workout-stats.component.css'
})
export class WorkoutStatsComponent implements OnInit {
  private kcalPerSet: number = 8;
  private minsPerSet: number = 2.5;

  @Input()
  public exercises: Exercise[] = [];
  public dataSource!: WorkoutMetric[];
  public columnsToDisplay: string[] = ['name', 'value', 'unit'];
  public columnsToDisplayWithExpand: string[] = [...this.columnsToDisplay];
  public expandedMetric?: WorkoutMetric;

  ngOnInit(): void {
    this.calculate();
  }

  public calculate(): void {
    let kcal = 0;
    let time = 0;
    let muscles = [...new Set(this.exercises.map(e => WorkoutService.intToGroup(e.muscleGroup)))].join(', ');

    this.exercises.forEach(exercise => {
      if (exercise.muscleGroup == 7 || exercise.muscleGroup?.toString() == 'Cardio') {
        exercise.sets.forEach(set => {
          let factor = 1;

          if (set.load == 3) {
            factor = 1.25;
          }
          else if (set.load == 5) {
            factor = 1.5;
          }

          kcal += this.kcalPerSet * (set.repetitions ?? 0)/2 * factor;
          time += set.repetitions ?? 0;
        });
      }
      else {
        kcal += Math.trunc(exercise.sets.length * this.kcalPerSet);
        time += Math.trunc(exercise.sets.length * this.minsPerSet);
      }
    });

    this.dataSource = [
      { name: "Calories", description: "These values are rough estimates and should not be considered for any fitness or medical assessment. The number of calories burned during exercise varies depending on multiple factors, such as the individual's weight, the intensity and duration of the activity, their metabolic rate, and even environmental conditions like temperature and humidity. These variables interact dynamically, making it crucial to consider a combination of factors when estimating calorie expenditure during physical activity.", value: `${kcal.toString()} to ${(kcal * 1.5).toString()}`, unit: "kcal" },
      { name: "Est. time", description: "These values are rough estimates and should not be considered for any fitness or medical assessment. The recommended exercise time serves as a general guideline, but its applicability may fluctuate depending on individual factors such as fitness level, underlying health conditions, and personal goals. As such, it's essential for individuals to listen to their bodies, adjust their routines accordingly, and consult with healthcare professionals for personalized exercise recommendations.", value: time.toString(), unit: "mins" },
      { name: "Targets", description: "These were the primary muscle groups worked during this workout.", value: muscles, unit: "" },
      // { name: "Estimated 1RM*", description: "* For bench press", value: "200", unit: "lbs" }
    ];
  }
}

export interface WorkoutMetric {
  name: string;
  description: string;
  value: string;
  unit: string;
}