import { TimeService } from "../../services/utilities/time.service";

export class WorkoutDate {
    public date: string;

    constructor(date: string | undefined = undefined) {
        if (date) {
            this.date = TimeService.getStringFromDate(new Date(date));
        }
        else {
            this.date = TimeService.getStringFromDate(new Date());
        }
    }
}