<mat-dialog-content>
  <h2>Select Muscle Group</h2>
  <p>What muscle group is this exercise for?</p>
  <mat-form-field class="w-100">
    <mat-label>Muscle Group</mat-label>
    <mat-select [(ngModel)]="data.muscle">
        <mat-option value="" selected>Proceed without selecting</mat-option>
        <mat-option value="Chest">Chest</mat-option>
        <mat-option value="Back">Back</mat-option>
        <mat-option value="Shoulders">Shoulders</mat-option>
        <mat-option value="Biceps">Biceps</mat-option>
        <mat-option value="Triceps">Triceps</mat-option>
        <mat-option value="Legs">Legs</mat-option>
        <mat-option value="Core">Core</mat-option>
        <mat-option value="Glutes">Glutes</mat-option>
        <mat-option value="Cardio">Cardio</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()" [mat-dialog-close]="null">CANCEL</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data.muscle">OK</button>
</mat-dialog-actions>