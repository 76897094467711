import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { WorkoutComponent } from '../workout/workout.component';
import { HistoryComponent } from '../history/history.component';
import { MovementGroup } from '../../../models/presentation/movement-group.model';
import { WorkoutService } from '../../../services/workout/workout.service';
import { MovementPattern } from '../../../models/domain/movement-pattern.model';
import { DialogService } from '../../../services/utilities/dialog.service';
import { environment } from '../../../environments/environment';
import { ExerciseService } from '../../../services/exercise/exercise.service';

@Component({
  selector: 'app-logger',
  standalone: true,
  imports: [
    MatTabsModule,
    WorkoutComponent,
    HistoryComponent
  ],
  templateUrl: './logger.component.html',
  styleUrl: './logger.component.css'
})
export class LoggerComponent implements OnInit {
  public movementGroups: MovementGroup[] = [];

  constructor(private workoutService: WorkoutService,
              private exerciseService: ExerciseService,
              private dialogService: DialogService) {}
  
  ngOnInit(): void {
    this.loadMovementGroups();
  }

  private loadMovementGroups() {
    this.exerciseService.getMovementPatterns().subscribe({
      next: (movements: MovementPattern[]) => {
        this.movementGroups = this.workoutService.getMovementGroups(movements);
      },
      error: (err: any) => {
        this.dialogService.showSnackbar("Unable to reach the server right now. Try again later.", "DISMISS", 2000);
        
        if (!environment.production) {
          console.log(err);
        }
      },
      complete: () => { }
    });
  }
}