import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from '../utilities/storage.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  private readonly _baseUri: string = environment.baseApiURL;

  constructor(protected http: HttpClient, protected storageService: StorageService) { }

  protected get<T>(endpoint: string, authenticate: boolean = true): Observable<T> {
    let uri = this._baseUri + endpoint;

    return this.http.get<T>(uri, {
      headers: this.getDefaultHeaders(authenticate)
    });
  }

  protected post<T>(endpoint: string, body: any, authenticate: boolean = true): Observable<T> {
    let uri: string = this._baseUri + endpoint;

    return this.http.post<T>(uri, body, {
      headers: this.getDefaultHeaders(authenticate)
    });
  }

  private getDefaultHeaders(authenticate: boolean): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    if (authenticate) {
      let token = this.storageService.getStorage("t");
      headers = headers.append("Authorization", `Bearer ${token}`);
    }

    return headers;
  }
}