import { Injectable } from '@angular/core';
import { ApiService } from '../abstract/api.service';
import { Observable } from 'rxjs';
import { Workout } from '../../models/domain/workout.model';
import { MovementPattern } from '../../models/domain/movement-pattern.model';
import { WorkoutDate } from '../../models/presentation/workout-date.model';
import { MovementGroup } from '../../models/presentation/movement-group.model';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService extends ApiService {
  private readonly endpoint: string = "Workout/"

  public getWorkout(date: WorkoutDate): Observable<Workout> {
    return this.post(this.endpoint + "GetWorkout", date);
  }

  public saveWorkout(workout: Workout): Observable<any> {
    return this.post(this.endpoint + "SaveWorkout", workout);
  }

  public getMovementGroups(movements: MovementPattern[]): MovementGroup[] {
    let groups = movements.map(p => p.targetMuscleGroup).filter((itm, i, a) => i == a.indexOf(itm)).sort();
    let movementGroups: MovementGroup[] = [];

    groups.forEach(group => {
      let p = movements.filter(m => m.targetMuscleGroup == group);
      let g: MovementGroup = {
        name: WorkoutService.intToGroup(group),
        movements: p
      };

      movementGroups.push(g);
    });

    return movementGroups;
  }

  public static intToGroup(group: any) {
    switch (group) {
      case 0: return "Chest";
      case 1: return "Back";
      case 2: return "Shoulders";
      case 3: return "Biceps";
      case 4: return "Legs";
      case 5: return "Core";
      case 6: return "Glutes";
      case 7: return "Cardio";
      case 8: return "Triceps";
      default: return group;
    }
  }
}