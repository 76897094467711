import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'muscle-group-dialog',
    templateUrl: 'muscle-group-dialog.component.html',
    standalone: true,
    imports: [
      MatFormFieldModule,
      MatInputModule,
      FormsModule,
      MatButtonModule,
      MatSelectModule,
      MatDialogTitle,
      MatDialogContent,
      MatDialogActions,
      MatDialogClose,
    ],
  })
  export class MuscleGroupDialog {
    constructor(public dialogRef: MatDialogRef<MuscleGroupDialog>,
      @Inject(MAT_DIALOG_DATA) public data: MuscleDialogData) { }
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  }
  
  export interface MuscleDialogData {
    muscle?: string;
  }