import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private snackBar: MatSnackBar) {}

  public showSnackbar(message: string, action: string, duration: number | undefined = undefined): void {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'right';
        let verticalPosition: MatSnackBarVerticalPosition = 'top';

        this.snackBar.open(message, action, {
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
          duration: duration
        });
  }
}
