import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-floating-menu',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './floating-menu.component.html',
  styleUrl: './floating-menu.component.css'
})
export class FloatingMenuComponent implements OnInit, OnDestroy {
  @Input()
  public options?: string[];
  @Input()
  public icons?: string[];
  @Output()
  public onClick:EventEmitter<string> = new EventEmitter<string>();

  public show: boolean = false;

  public ngOnInit(): void {
    let body = document.querySelector("body");
    body?.addEventListener('scroll', this.onScroll);
  }

  public ngOnDestroy(): void {
    let body = document.querySelector("body");
    body?.removeEventListener('scroll', this.onScroll);
  }

  public onButtonClick(option: string): void {
    this.onClick.emit(option);
  }

  private onScroll(event: any) {
    let y = document.body.scrollTop;
    let menu = document.querySelector('#menu');

    if (y > 200) {
      this.show = true;
      menu?.classList.remove('hide');
      menu?.classList.add('show');
    } else {
      this.show = false;
      menu?.classList.remove('show');
      menu?.classList.add('hide');
    }
  };
}
