import { Routes } from '@angular/router';
import { LoginComponent } from './components/common/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { inject } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { LoggerComponent } from './components/workouts/logger/logger.component';

export const routes: Routes = [
    { path: "", component: LoginComponent },
    { path: "dashboard", component: DashboardComponent, canActivate: [() => inject(AuthGuard).canActivate()] },
    { path: "logger", component: LoggerComponent, canActivate: [() => inject(AuthGuard).canActivate()] },
];
