import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  public todayLocal(): string {
    let today = new Date();
    let result = TimeService.getStringFromDate(today);

    return result;
  }

  public yesterdayLocal(): string {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let result = TimeService.getStringFromDate(yesterday);

    return result;
  }

  public static getStringFromDate(date: Date): string {
    let converted: string = date.toISOString();
    let actual = moment(converted);
    let result = actual.format('YYYY-MM-DDTHH:mm:ss');

    return result;
  }
}
