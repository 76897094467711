import { Component, OnInit } from '@angular/core';
import {
    MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TimeService } from '../../../../../../services/utilities/time.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'copy-workout-bottomsheet',
    templateUrl: 'copy-workout-bottomsheet.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatDividerModule,
        MatListModule
    ],
})
export class CopyWorkoutBottomSheet {
    public source?: string;
    public date?: string;
    public adjustments: 'none' | 'more-reps' | 'more-weight' = 'none';

    constructor(private bottomSheetRef: MatBottomSheetRef<CopyWorkoutBottomSheet>) {
        this.source = 'calendar';
        let lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        this.date = TimeService.getStringFromDate(lastWeek);
    }

    public select(event: MouseEvent): void {
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }

    public confirm(): void {
        let result = {
            source: this.source,
            date: this.date,
            adjustments: this.adjustments
        } as CopyWorkoutSelections;

        this.bottomSheetRef.dismiss(result);
    }
}

export interface CopyWorkoutSelections {
    source?: string;
    date?: string;
    adjustments: 'none' | 'more-reps' | 'more-weight';
}