<mat-card class="login-card">
    <mat-card-header>
        <img src="../../../../assets/img/logo-white.png" width="200px" class="mb-3 m-auto">
    </mat-card-header>
    <mat-card-content>
        <form class="login-form">
            <mat-form-field class="w-100">
                <mat-label>Email</mat-label>
                <input matInput type="email" [(ngModel)]="credentials.username" name="username"
                    placeholder="Ex. pat@example.com">
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Password</mat-label>
                <input matInput type="password" [(ngModel)]="credentials.password" name="password"
                    placeholder="Password" (keydown.enter)="signIn()">
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-content>
        <button mat-raised-button (click)="signIn()" [disabled]="!(credentials.username && credentials.password)"
            color="primary" class="mt-3 w-100">SIGN IN</button>
    </mat-card-content>
    <mat-card-content class="text-center mt-3">
        <a href="#">Create account</a>
        <br />
        <a href="#">Forgot password</a>
    </mat-card-content>
    <mat-card-footer class="mt-3">
        <mat-progress-bar [mode]="loginStatus" value="0"></mat-progress-bar>
    </mat-card-footer>
</mat-card>