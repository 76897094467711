import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
    selector: 'copy-workout-dialog',
    templateUrl: 'copy-workout-dialog.component.html',
    standalone: true,
    imports: [
      MatFormFieldModule,
      MatInputModule,
      FormsModule,
      MatButtonModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatDialogTitle,
      MatDialogContent,
      MatDialogActions,
      MatDialogClose,
    ],
  })
  export class CopyWorkoutDialog {
    constructor(public dialogRef: MatDialogRef<CopyWorkoutDialog>,
      @Inject(MAT_DIALOG_DATA) public data: CopyWorkoutDialogData) { }
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  }
  
  export interface CopyWorkoutDialogData {
    date?: string;
  }