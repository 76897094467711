<mat-toolbar class="navbar">
    <span>
        <img src="../../../../assets/img/logo-white.png" width="125px" class="mt-3">
    </span>
    <span class="spacer"></span>

    <button mat-icon-button class="icon" [routerLink]="['/dashboard']" matTooltip="Dashboard" disabled>
        <mat-icon color="secondary">speed</mat-icon>
    </button>

    <button mat-icon-button class="icon" [matMenuTriggerFor]="workoutsMenu" matTooltip="Workouts">
        <mat-icon color="primary">fitness_center</mat-icon>
    </button>
    <mat-menu #workoutsMenu="matMenu">
        <button mat-menu-item disabled>
            <mat-icon>fitness_center</mat-icon>
            <span>My Workouts</span>
        </button>
        <button mat-menu-item disabled>
            <mat-icon>date_range</mat-icon>
            <span>Weekly Plan</span>
        </button>
        <button mat-menu-item [routerLink]="['logger']">
            <mat-icon>history</mat-icon>
            <span>Log Book</span>
        </button>
    </mat-menu>

    <button mat-icon-button class="icon" matTooltip="Body Composition" disabled>
        <mat-icon color="secondary">accessibility</mat-icon>
    </button>

    <button mat-icon-button class="icon" [matMenuTriggerFor]="userMenu" matTooltip="Account">
        <mat-icon color="primary">person</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item disabled>
            <mat-icon>settings</mat-icon>
            <span>Preferences</span>
        </button>
        <button mat-menu-item (click)="signOut()">
            <mat-icon>logout</mat-icon>
            <span>Sign Out</span>
        </button>
    </mat-menu>

    <!-- <button mat-icon-button class="icon" (click)="drawer.toggle()">
      <mat-icon>more_vert</mat-icon>
    </button> -->
</mat-toolbar>

<!-- <mat-drawer-container class="drawer-container">
    <mat-drawer #drawer class="sidenav" mode="side" position="end">
      <button mat-button style="width: 100%;">Button</button>
    </mat-drawer>
</mat-drawer-container> -->