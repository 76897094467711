@if (isLoaded) {
<h2 mat-dialog-title>{{ data.movement.name }}</h2>
<mat-dialog-content class="mat-typography">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Load">
            <canvas baseChart class="chart" [data]="loadChartData" [options]="loadChartOptions" [type]="lineChartType"
                class="mt-3"></canvas>
        </mat-tab>
        <mat-tab label="Reps">
            <canvas baseChart class="chart" [data]="repsChartData" [options]="repsChartOptions" [type]="lineChartType"
                class="mt-3"></canvas>
        </mat-tab>
        <mat-tab label="Volume">
            <canvas baseChart class="chart" [data]="volumeChartData" [options]="volumeChartOptions" [type]="lineChartType"
                class="mt-3"></canvas>
        </mat-tab>
    </mat-tab-group>
    <mat-divider class="mt-3 mobile-hide"></mat-divider>

    <table mat-table [dataSource]="metrics" class="mat-elevation-z8 mt-3 mobile-hide">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let metric"> {{metric.date | date: 'MM-dd'}} </td>
        </ng-container>

        <ng-container matColumnDef="averageLoad">
            <th mat-header-cell *matHeaderCellDef> Average Load </th>
            <td mat-cell *matCellDef="let metric"> {{metric.averageLoad | number:'1.0-1'}} </td>
        </ng-container>

        <ng-container matColumnDef="maxLoad">
            <th mat-header-cell *matHeaderCellDef> Max Load </th>
            <td mat-cell *matCellDef="let metric"> {{metric.maxLoad | number:'1.0-1'}} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef> Unit </th>
            <td mat-cell *matCellDef="let metric">
                @if (metric.unit == 0) {
                lbs
                }
                @else if (metric.unit == 1) {
                kg
                }
                @else {
                mins
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="averageReps">
            <th mat-header-cell *matHeaderCellDef> Average Reps </th>
            <td mat-cell *matCellDef="let metric"> {{metric.averageReps | number:'1.0-0'}} </td>
        </ng-container>

        <ng-container matColumnDef="maxReps">
            <th mat-header-cell *matHeaderCellDef> Max Reps </th>
            <td mat-cell *matCellDef="let metric"> {{metric.maxReps | number:'1.0-0'}} </td>
        </ng-container>

        <ng-container matColumnDef="sets">
            <th mat-header-cell *matHeaderCellDef> Sets </th>
            <td mat-cell *matCellDef="let metric"> {{metric.sets | number:'1.0-0'}} </td>
        </ng-container>

        <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef> Volume </th>
            <td mat-cell *matCellDef="let metric"> {{metric.volume | number:'1.0-0'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>CLOSE</button>
</mat-dialog-actions>
}
@else {
<div style="min-width: 70vw; min-height: 50vh;">
    <mat-spinner color="primary" diameter="28" class="m-auto" style="margin-top: 22vh !important;"></mat-spinner>
</div>
}