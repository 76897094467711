<div class="today-container mt-3">
    <div class="add-button-container mb-3">
        <button mat-mini-fab color="primary" (click)="openMuscleDialog()" matTooltip="Add Exercise"
            [disabled]="!isLoaded">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-mini-fab color="primary" matTooltip="Save" class="ml-3" (click)="save()"
            [disabled]="!isLoaded">
            <mat-icon>save</mat-icon>
        </button>
    </div>

    @if (isLoaded) {
        @if (workout!.exercises && workout!.exercises.length > 0) {
            <app-workout-stats #stats [exercises]="workout!.exercises"></app-workout-stats>
        }

        @for (exercise of workout!.exercises; track $index) {
        <app-exercise [exercise]="exercise" [index]="$index" [movementGroups]="movementGroups"
            (onCopyExercise)="copyExercise($event)" (onRemoveExercise)="removeExercise($event)"
            (setsChanged)="setsChanged()"></app-exercise>
        }
        @empty {
        <div class="text-center">
            <p>Add an exercise to start tracking!</p>
                
            <button mat-raised-button color="primary" (click)="openMuscleDialog()" class="m-1" style="width: 200px;">ADD EXERCISE</button>
            <button mat-raised-button color="primary" (click)="openCopyBottomSheet()" class="m-1" style="width: 200px;">COPY WORKOUT</button>
        </div>
        }
    }
    @else if (isError) {
        <p>Unable to retrieve workouts right now. Please try again later.</p>
        <p class="text-light-faded">Incident date: {{ date!.date }}</p>
    }
    @else {
        <mat-spinner diameter="28" class="m-auto mt-3"></mat-spinner>
    }
</div>

<app-floating-menu [options]="floatingMenuOptions" 
                   [icons]="floatingMenuOptionIcons"
                   (onClick)="onSelect($event)"></app-floating-menu>