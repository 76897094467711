import { TimeService } from "../../services/utilities/time.service";
import { Exercise } from "./exercise.model";
import { v4 as uuidv4 } from 'uuid';

export class Workout {
    public id!: string;
    public userId?: string;
    public date!: string;
    public exercises!: Exercise[];

    constructor(date: string | undefined = undefined) {
        this.id = uuidv4();
        this.exercises = [];

        if (date) {
            this.date = TimeService.getStringFromDate(new Date(date));
        }
        else {
            this.date = TimeService.getStringFromDate(new Date());
        }
    }
}