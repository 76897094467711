import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MovementGroup } from '../../../models/presentation/movement-group.model';
import { MatButtonModule } from '@angular/material/button';
import { Exercise } from '../../../models/domain/exercise.model';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ExerciseSet } from '../../../models/domain/exercise-set.model';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MovementPattern } from '../../../models/domain/movement-pattern.model';
import { WorkoutService } from '../../../services/workout/workout.service';
import { DialogService } from '../../../services/utilities/dialog.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExerciseStatsDialog, ExerciseStatsDialogData } from './dialogs/exercise-stats/exercise-stats-dialog.component';

@Component({
  selector: 'app-exercise',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatGridListModule,
    MatDividerModule,
    MatMenuModule,
    MatChipsModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule
  ],
  templateUrl: './exercise.component.html',
  styleUrl: './exercise.component.css'
})
export class ExerciseComponent implements OnInit {
  private _exercise!: Exercise;
  private _movementGroups!: MovementGroup[];
  private allMovementGroups?: MovementGroup[];
  public notesHeight: number = 3;
  public isFiltered: boolean = false;
  public filter?: string;
  public isOpen: boolean = false;
  public movement?: MovementPattern;
  public isExpanded: boolean = false;

  @Input()
  public set exercise(value: Exercise) {
    this._exercise = value as Exercise;
    let filter = WorkoutService.intToGroup(value.muscleGroup);

    this.loadMovement();
    this.loadFilter(filter);
  }
  public get exercise(): Exercise {
    return this._exercise;
  }
  @Input()
  public index!: number;
  @Input()
  public set movementGroups(value: MovementGroup[]) {
    if (!value || value.length == 0) {
      return;
    }

    this._movementGroups = value;

    if (!this.allMovementGroups) {
      this.allMovementGroups = [...value];
    }
  }
  public get movementGroups(): MovementGroup[] {
    return this._movementGroups;
  }
  @Output()
  public onCopyExercise: EventEmitter<Exercise> = new EventEmitter<Exercise>();
  @Output()
  public onRemoveExercise: EventEmitter<Exercise> = new EventEmitter<Exercise>();
  @Output()
  public setsChanged: EventEmitter<void> = new EventEmitter();

  constructor(private dialogService: DialogService, private dialog: MatDialog) { }

  public ngOnInit(): void {
    this.loadFilter();

    this.isExpanded = this.exercise.movementPatternId == undefined;
  }

  public addSet(): void {
    let set = new ExerciseSet(this.exercise.sets.length);

    // TODO: If first set, load set recommendation based on last workout.
    if (this.exercise.sets.length > 0) {
      let last = this.exercise.sets[this.exercise.sets.length - 1];
      
      set.repetitions = last.repetitions;
      set.load = last.load;
    }

    this.exercise.sets.push(set);
    this.setsChanged.emit();

    if (this.updateNotesHeight()) {
      this.notesHeight = this.exercise.sets.length < 4 ? 3 : this.exercise.sets.length;

      if (this.notesHeight > 4) {
        this.notesHeight += 2;
      }
    }
    else {
      this.notesHeight = 3;
    }
  }

  public removeSet(): void {
    this.exercise.sets.pop();
    this.setsChanged.emit();

    if (this.updateNotesHeight()) {
      this.notesHeight = this.exercise.sets.length < 4 ? 3 : this.exercise.sets.length;

      if (this.notesHeight > 4) {
        this.notesHeight += 2;
      }
    }
    else {
      this.notesHeight = 3;
    }
  }

  public copyExercise(): void {
    this.onCopyExercise.emit(this.exercise);
  }

  public removeExercise(): void {
    this.onRemoveExercise.emit(this.exercise);
  }

  public setFilter(value: string | undefined) {
    let movements = this.allMovementGroups!;
    this.isFiltered = value != undefined;
    this.filter = value;

    if (!value) {
      this.movementGroups = movements!;

      return;
    }

    this.movementGroups = movements.filter(g => g.name == value);
  }

  public loadFilter(filter?: string) {
    setTimeout(() => {
      if (this.exercise && this.allMovementGroups) {
        if (filter) {
          this.setFilter(filter);
        }
        else {
          this.movement = this.allMovementGroups.map(m => m.movements).flat().filter(m => m.id == this.exercise.movementPatternId)[0];

          if (this.movement) {
            this.setFilter(WorkoutService.intToGroup(this.movement.targetMuscleGroup));
          }
        }
      }
      else {
        this.loadFilter(filter);
      }
    }, 250);
  }

  public loadMovement() {
    // Should anything be done here if allMovementGroups are undefined?
    // Or is this always a self-resolving problem?
    // if (!this.allMovementGroups) {
    //   console.log('allMovementGroups is undefined.');
    // }

    setTimeout(() => {
      if (this.exercise && this.allMovementGroups) {
        let movement = this.allMovementGroups.map(m => m.movements).flat().filter(m => m.id == this.exercise.movementPatternId)[0];

        if (movement) {
          this.movement = movement;
        }
        else {
          this.loadMovement();
        }
      }
      else {
        this.loadMovement();
      }
    }, 250);
  }

  public getStats(): void {
    if (!this.exercise || !this.exercise.movementPatternId || !this.movement) {
      this.dialogService.showSnackbar("An exercise selection is required to load metrics.", "DISMISS", 2000);

      return;
    }

    let parameters: ExerciseStatsDialogData = {
      parameters: {
        movementPatternId: this.movement.id,
        variation: this.exercise.variation,
        equipment: this.exercise.equipment,
        advancedTechnique: this.exercise.advancedTechnique
      },
      movement: this.movement
    };

    this.dialog.open(ExerciseStatsDialog, {
      data: parameters
    });
  }

  public setsUpdated(): void {
    this.setsChanged.emit();
  }

  private updateNotesHeight(): boolean {
    return window.matchMedia("(min-width: 769px)").matches;
  }
}