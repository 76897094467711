<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>Workout Stats</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-3">
            @for (column of columnsToDisplay; track column) {
            <ng-container matColumnDef="{{column}}">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let metric" [style]="$index == 0 ? 'min-width: 120px !important;' : ''">
                    <p>
                        {{metric[column]}}

                        @if ($index == 0) {
                        <mat-icon fontIcon="info_outline" class="info-icon"></mat-icon>
                        }
                    </p>
                </td>
            </ng-container>
            }
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let metric">
                    <button mat-icon-button aria-label="expand row"
                        (click)="(expandedMetric = expandedMetric === metric ? null : metric); $event.stopPropagation()">
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let metric" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div class="table-entry-detail"
                        [@detailExpand]="metric == expandedMetric ? 'expanded' : 'collapsed'">
                        <p>{{metric.description}}</p>
                    </div>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let metric; columns: columnsToDisplayWithExpand;" class="table-entry-row"
                [class.example-expanded-row]="expandedMetric === metric"
                (click)="expandedMetric = expandedMetric === metric ? null : metric">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-entry-detail-row"></tr>
        </table>
    </mat-expansion-panel>
</mat-accordion>