import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavigationComponent } from './components/common/navigation/navigation.component';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { environment } from './environments/environment';
import { version } from './environments/version';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NavigationComponent,
    FooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoggedIn: boolean = false;
  private loggedInSub?: Subscription;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    console.log(`Version: ${version}`);

    if (environment.env != "production") {
      console.log(`Environment: ${environment.env}`);
    }

    this.isLoggedIn = this.authService.isAuthenticated();

    this.authService.onAuthStatusChanged.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  ngOnDestroy(): void {
    this.loggedInSub?.unsubscribe();
  }
}
