import { ExerciseSet } from "./exercise-set.model";
import { v4 as uuidv4 } from 'uuid';
import { MuscleGroup } from "./muscle-group.model";

export class Exercise {
    public id!: string;
    public order!: number;
    public movementPatternId?: string;
    public muscleGroup?: MuscleGroup;
    public sets!: ExerciseSet[];
    public notes?: string;
    public variation?: string = 'No Selection';
    public equipment?: string = 'No Selection';
    public advancedTechniqueOptions?: string[];
    public advancedTechnique?: string;

    constructor(order: number) {
        this.id = uuidv4();
        this.sets = [];
        this.notes = '';
        this.order = order;
        this.advancedTechnique = "None";
        this.advancedTechniqueOptions = [
            "None",
            "Dropset",
            "Rest Pause",
            "Myoreps",
            "Myo-Match",
            "Assisted Reps",
            "Negatives",
            "Superset with Next",
            "Superset with Previous"
        ];
    }
}