import { Component, Input } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { WorkoutComponent } from '../workout/workout.component';
import { FormsModule } from '@angular/forms';
import { WorkoutDate } from '../../../models/presentation/workout-date.model';
import { MovementGroup } from '../../../models/presentation/movement-group.model';
import { TimeService } from '../../../services/utilities/time.service';

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    WorkoutComponent
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.css'
})
export class HistoryComponent {
  private _selectedDate?: string | undefined;

  @Input()
  public movementGroups!: MovementGroup[];
  public date?: WorkoutDate;
  public get selectedDate(): string | undefined {
    return this._selectedDate;
  }
  public set selectedDate(value: string | undefined) {
    this._selectedDate = value;
    this.date = new WorkoutDate(value);
  }

  constructor(private timeService: TimeService) {
    this.selectedDate = this.timeService.yesterdayLocal();
    this.date = new WorkoutDate(this.selectedDate);
  }
}
