import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthenticationService, private router: Router) {}

  public canActivate(): boolean {
    let isLoggedIn = this.authService.isAuthenticated();

    if (!isLoggedIn) {
      this.router.navigate([""]);
    }

    return isLoggedIn;
  }
}