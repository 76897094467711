<mat-accordion>
    <mat-expansion-panel [expanded]="isExpanded" (opened)="isOpen = true" (closed)="isOpen = false" class="mt-3">
        <mat-expansion-panel-header>
            <mat-panel-title>
                @if (movement) {
                <h3>{{movement.name}}
                    @if (filter) {
                    <span class="text-light-faded">({{filter}})</span>
                    }
                </h3>
                }
                @else {
                <h3>Exercise {{index + 1}}</h3>
                }
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="card-header">
            <span>
                <h3>Exercise Information</h3>
            </span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon color="accent">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="copyExercise()">
                    <span>Clone</span>
                </button>
                <button mat-menu-item (click)="removeExercise()">
                    <span>Remove</span>
                </button>
                <button mat-menu-item (click)="getStats()" [disabled]="!movement">
                    <span>Stats</span>
                </button>
                <button mat-menu-item [disabled]="true">
                    <span>Rate</span>
                </button>
            </mat-menu>
        </div>
        <mat-divider></mat-divider>

        <div class="details-container">
            <div class="panel-left w-100">
                <div class="details-header">
                    <div class="w-100">
                        <p>Details</p>
                    </div>
                    <div class="filter-container">
                        @if (filter) {
                        <mat-chip-option selected (click)="setFilter('')" style="margin-top: 0.5rem;">
                            {{ filter }}
                        </mat-chip-option>
                        }
                        @else {
                        <button mat-icon-button [matMenuTriggerFor]="addMenu">
                            <mat-icon [color]="false ? 'primary' : 'accent'">filter_alt</mat-icon>
                        </button>
                        }
                    </div>
                </div>
                <mat-divider class="mb-3"></mat-divider>

                <mat-form-field class="w-100">
                    <mat-label>Movement</mat-label>
                    <mat-select [(ngModel)]="exercise.movementPatternId" (selectionChange)="loadFilter()" required>
                        @for (group of movementGroups; track group) {
                        <mat-optgroup [label]="group.name!">
                            @for (movement of group.movements; track movement) {
                            <mat-option [value]="movement.id">{{movement.name}}</mat-option>
                            }
                        </mat-optgroup>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-menu #addMenu="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="upperMenu">Upper Body</button>
                    <button mat-menu-item [matMenuTriggerFor]="lowerMenu">Lower Body</button>
                    <button mat-menu-item (click)="setFilter('Core')">Core</button>
                    <button mat-menu-item (click)="setFilter('Cardio')">Cardio</button>
                </mat-menu>
                <mat-menu #upperMenu="matMenu">
                    <button mat-menu-item (click)="setFilter('Biceps')">Biceps</button>
                    <button mat-menu-item (click)="setFilter('Triceps')">Triceps</button>
                    <button mat-menu-item (click)="setFilter('Back')">Back</button>
                    <button mat-menu-item (click)="setFilter('Chest')">Chest</button>
                    <button mat-menu-item (click)="setFilter('Shoulders')">Shoulders</button>
                </mat-menu>
                <mat-menu #lowerMenu="matMenu">
                    <button mat-menu-item (click)="setFilter('Legs')">Legs</button>
                    <button mat-menu-item (click)="setFilter('Glutes')">Glutes</button>
                </mat-menu>

                @if(filter != "Cardio" && movement?.variationOptions) {
                <mat-form-field class="w-100">
                    <mat-label>Variation (optional)</mat-label>
                    <mat-select [(ngModel)]="exercise.variation">
                        @for (variation of movement!.variationOptions; track variation) {
                        <mat-option [value]="variation">{{variation}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                }

                @if(filter != "Cardio" && movement?.equipmentOptions) {
                <mat-form-field class="w-100">
                    <mat-label>Equipment (optional)</mat-label>
                    <mat-select [(ngModel)]="exercise.equipment">
                        @for (equipment of movement!.equipmentOptions; track equipment) {
                        <mat-option [value]="equipment">{{equipment}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                }

                <p>Notes</p>
                <mat-divider class="mb-3"></mat-divider>
                <mat-form-field class="w-100">
                    <mat-label>Add notes</mat-label>
                    <textarea matInput [(ngModel)]="exercise.notes" [rows]="notesHeight"
                        placeholder="Ex. Dropsets of 50% per set. Myoreps of 3-6 on the last set."></textarea>
                </mat-form-field>

                @if (exercise.sets.length > 7) {
                <span class="pro-tip">
                    <mat-icon inline="true" style="color: #bababa;">lightbulb</mat-icon>
                    <span class="text-muted">Pro tip: Fewer sets per exercise may yield better stimuli diversity.</span>
                </span>
                }
            </div>
            <div class="panel-right w-100">
                <p>Execution</p>
                <mat-divider class="mb-3"></mat-divider>

                @if(filter != "Cardio" && movement && exercise.advancedTechniqueOptions && exercise.sets) {
                <mat-form-field class="w-100">
                    <mat-label>Advanced Technique (optional)</mat-label>
                    <mat-select [(ngModel)]="exercise.advancedTechnique">
                        @for (technique of exercise!.advancedTechniqueOptions; track technique) {
                        <mat-option [value]="technique">{{technique}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                }

                @for (set of exercise.sets; track $index) {
                <mat-grid-list rows="1" cols="5" gutterSize="1rem" rowHeight="75px">
                    <mat-grid-tile>
                        <p>Set {{$index + 1}}</p>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="2">
                        <mat-form-field class="w-100">
                            @if (filter == "Cardio") {
                            <mat-label>Mins</mat-label>
                            }
                            @else {
                            <mat-label>Reps</mat-label>
                            }
                            <input matInput type="number" inputmode="numeric" pattern="[0-9]*"
                                [(ngModel)]="set.repetitions" (change)="setsUpdated()" placeholder="Ex. 10" required>
                            @if (filter == "Cardio") {
                            <span matSuffix class="pr-3">mins</span>
                            }
                            @else {
                            <span matSuffix class="pr-3">reps</span>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="2">
                        @if (filter == "Cardio") {
                        <mat-form-field class="w-100">
                            <mat-label>Intensity</mat-label>
                            <mat-select [(ngModel)]="set.load" required (selectionChange)="setsUpdated()">
                                <mat-option [value]="1">Low</mat-option>
                                <mat-option [value]="3">Moderate</mat-option>
                                <mat-option [value]="5">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                        }
                        @else {
                        <mat-form-field class="w-100">
                            <mat-label>Load</mat-label>
                            <input matInput type="number" inputmode="decimal" step="0.1"
                                pattern="[+ -]?[0-9]{1,3}([.][0-9]{1,2})?" [(ngModel)]="set.load"
                                (change)="setsUpdated()" placeholder="Ex. 25" required>
                            <span matSuffix class="pr-3">{{set.unit == 0 ? 'lbs' : 'kg'}}</span>
                        </mat-form-field>
                        }
                    </mat-grid-tile>
                </mat-grid-list>
                }
                @empty {
                <p>Add exercise sets for better progress tracking.</p>
                }

                <mat-grid-list rows="1" cols="2" gutterSize="1rem" rowHeight="3:1">
                    <mat-grid-tile>
                        <button mat-raised-button color="primary" (click)="addSet()" class="w-100">ADD SET</button>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <button mat-raised-button color="primary" (click)="removeSet()" class="w-100"
                            [disabled]="exercise.sets.length < 1">REMOVE SET</button>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>