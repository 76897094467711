import { Injectable } from '@angular/core';
import { ApiService } from '../abstract/api.service';
import { AuthenticationToken } from '../../models/authentication/authentication-token.model';
import { Observable, Subject } from 'rxjs';
import { Credentials } from '../../models/authentication/credentials.model';
import { JwtService } from '../utilities/jwt.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../utilities/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ApiService {
  private readonly endpoint: string = "Auth/Login"

  private authenticationSubject: Subject<boolean>;
  public onAuthStatusChanged: Observable<boolean>;

  constructor(private jwt: JwtService, http: HttpClient, storageService: StorageService) {
    super(http, storageService);

    this.authenticationSubject = new Subject<boolean>();
    this.onAuthStatusChanged = this.authenticationSubject.asObservable();
  }

  public authenticate(credentials: Credentials,
                      success: (token: AuthenticationToken) => void,
                      error: (err: any) => void,
                      complete: () => void): void {
    let sub = this.post<AuthenticationToken>(this.endpoint, credentials).subscribe({
      next: (token: AuthenticationToken) => {
        this.storageService.clearStorage();
        this.storageService.setStorage("t", token.token);
        this.authenticationSubject.next(this.isAuthenticated());
        success(token);
      },
      error: (err: any) => error(err),
      complete: () => {
        complete();
        sub.unsubscribe();
      }
    });
  }

  public isAuthenticated(): boolean {
    let token = this.storageService.getStorage("t");

    if (!token) {
      return false;
    }

    let isExpired: boolean = this.jwt.isExpired(token);

    return !isExpired;
  }

  public signOut(): void {
    this.storageService.clearStorage();
    this.authenticationSubject.next(this.isAuthenticated());
  }
}
